import styled from '@emotion/styled'
import LinkFormatter from 'components/LinkFormatter'

export const ImageWidthLimiter = styled.div`
  position: relative;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    width: 100%;
    max-width: 2000px;
    height: 100%;
    bottom: 0;
    right: 0;
  }
`

export const CtaContainer = styled(LinkFormatter)`
  font-size: ${props => props.theme.fonts.xxxs_large};
  text-decoration: none;
  cursor: pointer;
  width: 100px;
  white-space: pre-wrap;
  line-height: 1.19;

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    color: ${props => props.theme.colors.primarygreen}!important;
  }
`

export const BackToAllProjects = styled.div`
  font-weight: bold;

  a {
    color: ${props => props.theme.colors.primarygreen};
  }

  .back-arrow {
    margin-right: 10px;
    margin-left: 20px;
  }

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    position: absolute;
    width: 100%;
    height: 70px;
    background-color: white;
    padding-top: 250px;
  }

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding-top: 40px;
  }
`
