import React, { useContext, useEffect } from 'react'
import LocaleContext from 'context/LocaleProvider'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { getQuery } from 'functions/getQuery'
import ProjectPageLayout from 'components/PagesLayout/SingleProjectLayout'
import { withPreview } from 'gatsby-source-prismic'

const SingleProject = props => {
  const { data } = props
  const genericContentQuery = getQuery(['generic', 'nodes', 0, 'data'], data)
  const singleListingQuery = getQuery(['listing', 'nodes'], data)
  const globalContentQuery = getQuery(['global', 'nodes', 0, 'data'], data)
  const lang = useContext(LocaleContext)

  useEffect(() => {
    lang?.setPageDetails({
      uid: props?.pageContext?.alternativeLanguageUid,
      type: 'project',
    })
  }, [])

  return (
    <ProjectPageLayout
      query={genericContentQuery}
      projectQuery={singleListingQuery}
      globalQuery={globalContentQuery}
    />
  )
}

SingleProject.propTypes = {
  data: PropTypes.object.isRequired,
}

export default withPreview(SingleProject)

export const query = graphql`
  query SingleListingPage($uid: String!, $locale: String!) {
    listing: allPrismicSingleProjectPage(
      filter: { uid: { eq: $uid }, lang: { eq: $locale } }
    ) {
      nodes {
        uid
        lang
        data {
          open_graph_title {
            text
          }
          open_graph_description {
            text
          }
          open_graph_image {
            alt
            localFile {
              url
            }
          }
          seo_title {
            text
          }
          seo_description {
            text
          }
          all_categories_label {
            text
          }
          date
          hero_component {
            document {
              ... on PrismicHeroComponent {
                id
                data {
                  full_screen_height
                  body {
                    text
                  }
                  image {
                    alt
                    localFile {
                      childImageSharp {
                        fluid {
                          ...GatsbyImageSharpFluid_noBase64
                        }
                      }
                    }
                  }
                  title_style
                  title {
                    text
                  }
                  video {
                    url
                  }
                }
              }
            }
          }
          mosaic_thumbnail {
            alt
            localFile {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
          project_category
          project_excerpt {
            text
          }
          project_tags {
            text
          }
          project_title {
            text
          }
          secondary_hero_image {
            alt
            localFile {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
          body {
            ... on PrismicSingleProjectPageBodyProjectDetails {
              id
              primary {
                project_name {
                  text
                  html
                }
                location {
                  text
                  html
                }
                conception {
                  text
                  html
                }
                client {
                  text
                  html
                }
              }
            }
            ... on PrismicSingleProjectPageBodyImages {
              id
              items {
                image {
                  alt
                  localFile {
                    childImageSharp {
                      fluid {
                        ...GatsbyImageSharpFluid_noBase64
                      }
                    }
                  }
                }
                touch_the_edge
              }
            }
            ... on PrismicSingleProjectPageBodyCta {
              id
              items {
                page_type
                cta_styling
                cta_link {
                  uid
                }
                cta_label {
                  text
                }
              }
            }
            ... on PrismicSingleProjectPageBodyBasicContainer {
              id
              items {
                generic_component {
                  document {
                    ... on PrismicBasicContainer {
                      id
                      data {
                        heading_is_scrollable
                        font_weight_title_primary
                        font_weight_title_secondary
                        component_position
                        line_decoration
                        style_type
                        title_primary {
                          text
                        }
                        title_secondary {
                          text
                        }
                        body {
                          text
                        }
                        body1 {
                          ... on PrismicBasicContainerBody1Text {
                            id
                            items {
                              text_body {
                                html
                              }
                              text_line_decoration
                              text_title {
                                text
                              }
                            }
                          }
                          ... on PrismicBasicContainerBody1List {
                            id
                            items {
                              list {
                                text
                              }
                            }
                            primary {
                              list_line_decoration
                            }
                          }
                          ... on PrismicBasicContainerBody1Image {
                            id
                            items {
                              desktop_add_spacing_right
                              image_line_decoration
                              mobile_add_spacing_right
                              mobile_add_spacing_around
                              desktop_touch_the_right_edge
                              image {
                                alt
                                localFile {
                                  childImageSharp {
                                    fluid {
                                      ...GatsbyImageSharpFluid_noBase64
                                    }
                                  }
                                }
                              }
                            }
                          }
                          ... on PrismicBasicContainerBody1Cta {
                            id
                            items {
                              page_type
                              cta_styling
                              cta_link {
                                uid
                              }
                              cta_label {
                                text
                              }
                            }
                          }
                          ... on PrismicBasicContainerBody1Icons {
                            id
                            items {
                              icon_title {
                                text
                              }
                              icon_text {
                                text
                              }
                              icon_image {
                                dimensions {
                                  height
                                  width
                                }
                                alt
                                localFile {
                                  childImageSharp {
                                    fluid {
                                      ...GatsbyImageSharpFluid_noBase64
                                    }
                                  }
                                }
                              }
                            }
                          }
                          ... on PrismicBasicContainerBody1Logo {
                            id
                            items {
                              logo {
                                alt
                                localFile {
                                  childImageSharp {
                                    fluid {
                                      ...GatsbyImageSharpFluid_noBase64
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    global: allPrismicGlobalContent(filter: { lang: { eq: $locale } }) {
      nodes {
        data {
          scroll_down_label {
            text
          }
          read_more {
            text
          }
        }
      }
    }
    generic: allPrismicProjectsGeneral(filter: { lang: { eq: $locale } }) {
      nodes {
        lang
        data {
          cta {
            page_type
            cta_styling
            cta_link {
              uid
            }
            cta_label {
              text
            }
          }
          tab_1_title {
            text
          }
          tab_2_title {
            text
          }
          tab_3_title {
            text
          }
          tab_4_title {
            text
          }
        }
      }
    }
  }
`
