import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import LocaleContext from 'context/LocaleProvider'
import StyleContext from 'context/StyleProvider'
import Hero from 'components/Hero'
import ProjectDetailsBanner from 'components/ProjectDetailsBanner'
import Wrapper from 'components/Wrapper'
import WidthLimiter from 'components/Utilities/WidthLimiter'
import Icon from 'components/Icon'
import PreFooter from './PreFooter'
import Basic from 'components/Basic'
import { useMediaQuery } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import { Mobile, Desktop } from 'components/Utilities/Media'
import { WrapperContent } from 'components/Wrapper/styles.js'
import { ImageWidthLimiter, CtaContainer, BackToAllProjects } from './styles'

const ProjectPageLayout = ({ query, projectQuery, globalQuery }) => {
  const styleContext = useContext(StyleContext)
  const lang = useContext(LocaleContext)
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('769'))
  const i18n = lang.i18n[lang.locale]
  const singleProjectQuery = projectQuery?.[0]?.data
  const heroQuery = singleProjectQuery?.hero_component?.document?.data

  const genericComponentQuery = singleProjectQuery?.body?.find(
    query => query?.__typename === 'PrismicSingleProjectPageBodyBasicContainer'
  )

  const projectDetailsQuery = singleProjectQuery?.body?.find(
    query => query?.__typename === 'PrismicSingleProjectPageBodyProjectDetails'
  )?.primary

  const backToAllProjectsLink = query?.cta?.[0]?.cta_link?.uid

  const preFooterProps = {
    uid: projectQuery?.[0]?.uid,
    backToAllProjectsLabel: query?.cta?.[0]?.cta_label?.text,
    backToAllProjectsLink,
    projectCategory: singleProjectQuery.project_category,
    locale: i18n.locale,
  }

  const backTo = (
    <BackToAllProjects>
      <Icon
        type="back-arrow"
        className="back-arrow"
        style={styleContext?.styleActivated}
        isDesktop={isDesktop}
      />
      <CtaContainer
        pageHandle={backToAllProjectsLink}
        pageType="page"
        className="BackToAllProjects"
      >
        {query?.cta?.[0]?.cta_label?.text}
      </CtaContainer>
    </BackToAllProjects>
  )

  const heroProps = { ...heroQuery, globalQuery }

  return (
    <>
      <Hero {...heroProps} />
      <Wrapper hasFullHeight={heroQuery?.full_screen_height}>
        <WrapperContent>
          <Mobile>
            {query?.cta?.[0]?.cta_label.text &&
            query?.cta?.[0]?.cta_link?.uid ? (
              backTo
            ) : (
              <></>
            )}
          </Mobile>
          <ImageWidthLimiter>
            <ProjectDetailsBanner
              query={projectDetailsQuery}
              locale={i18n?.locale}
            />
          </ImageWidthLimiter>
          <WidthLimiter className="WidthLimiter__SingleProjectPage">
            <Desktop>
              {query?.cta?.[0]?.cta_label.text &&
              query?.cta?.[0]?.cta_link?.uid ? (
                backTo
              ) : (
                <></>
              )}
            </Desktop>
            <Basic {...genericComponentQuery} />
          </WidthLimiter>
          <PreFooter {...preFooterProps} />
        </WrapperContent>
      </Wrapper>
    </>
  )
}

ProjectPageLayout.propTypes = {
  query: PropTypes.object.isRequired,
  projectQuery: PropTypes.array.isRequired,
  globalQuery: PropTypes.object.isRequired,
}

export default ProjectPageLayout
