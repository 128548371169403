import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql, Link } from 'gatsby'
import WidthLimiter from 'components/Utilities/WidthLimiter'
import StyledLink from 'components/Utilities/StyledLink'
import { useMediaQuery } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import { PreFooterContainer, PrefooterCard, StyledTitle } from './styles'
import LinkFormatter from 'components/LinkFormatter'

const PreFooter = ({
  locale,
  projectCategory,
  uid,
  backToAllProjectsLabel,
  backToAllProjectsLink,
}) => {
  const data = useStaticQuery(graphql`
    {
      projects: allPrismicSingleProjectPage(
        sort: { fields: data___date, order: DESC }
      ) {
        nodes {
          lang
          uid
          data {
            mosaic_thumbnail {
              localFile {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
            }
            project_category
            all_categories_label {
              text
            }
            project_excerpt {
              text
            }
            project_tags {
              text
            }
            project_title {
              text
            }
          }
        }
      }
    }
  `)

  const localeProjects = data.projects.nodes.filter(
    node => node.lang === locale
  )

  let sameCategoryArticles = localeProjects?.filter(project => {
    return (
      project?.data?.project_category === projectCategory &&
      project?.uid !== uid
    )
  })

  if (!sameCategoryArticles.length) sameCategoryArticles = localeProjects

  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('769'))

  return (
    <>
      {sameCategoryArticles?.length > 0 ? (
        <PreFooterContainer>
          <WidthLimiter className="Project__Prefooter-widthLimiter">
            {sameCategoryArticles?.[0]?.uid &&
            sameCategoryArticles?.[0]?.data?.mosaic_thumbnail?.localFile
              ?.childImageSharp?.fluid &&
            sameCategoryArticles?.[0]?.data?.project_title?.text ? (
              <LinkFormatter
                pageType="project"
                pageHandle={sameCategoryArticles?.[0]?.uid}
                className="Prefooter__link"
              >
                <PrefooterCard
                  fluid={
                    sameCategoryArticles?.[0]?.data?.mosaic_thumbnail?.localFile
                      ?.childImageSharp?.fluid
                  }
                  className={`Prefooter__Image ${
                    !sameCategoryArticles?.[1] ? 'Prefooter__oneImageOnly' : ''
                  }`}
                  alt={sameCategoryArticles?.[0]?.data?.mosaic_thumbnail?.alt}
                >
                  <StyledTitle
                    className="Prefooter__Title"
                    type="h3"
                    whiteTitle
                  >
                    {sameCategoryArticles?.[0]?.data?.project_title?.text}
                  </StyledTitle>
                </PrefooterCard>
              </LinkFormatter>
            ) : (
              <></>
            )}
            {sameCategoryArticles?.[1] &&
            sameCategoryArticles?.[1]?.uid &&
            sameCategoryArticles?.[1]?.data?.mosaic_thumbnail?.localFile
              ?.childImageSharp?.fluid &&
            sameCategoryArticles?.[1]?.data?.project_title?.text ? (
              <LinkFormatter
                pageType="project"
                pageHandle={sameCategoryArticles?.[1]?.uid}
                className="Prefooter__link"
              >
                <PrefooterCard
                  fluid={
                    sameCategoryArticles?.[1]?.data?.mosaic_thumbnail?.localFile
                      ?.childImageSharp?.fluid
                  }
                  alt={sameCategoryArticles?.[1]?.data?.mosaic_thumbnail?.alt}
                >
                  <StyledTitle
                    className="Prefooter__Title"
                    type="h3"
                    whiteTitle
                  >
                    {sameCategoryArticles?.[1]?.data?.project_title?.text}
                  </StyledTitle>
                </PrefooterCard>
              </LinkFormatter>
            ) : (
              <></>
            )}
          </WidthLimiter>
          {backToAllProjectsLabel && backToAllProjectsLink ? (
            <StyledLink
              to={`/${backToAllProjectsLink}`}
              className="Prefooter__Link-seeAll"
            >
              {backToAllProjectsLabel}
            </StyledLink>
          ) : (
            <></>
          )}
        </PreFooterContainer>
      ) : (
        <div style={{ height: isDesktop ? '300px' : '0px' }}></div>
      )}
    </>
  )
}

PreFooter.propTypes = {
  locale: PropTypes.string.isRequired,
  projectCategory: PropTypes.string,
  uid: PropTypes.string,
  backToAllProjectsLabel: PropTypes.string,
  backToAllProjectsLink: PropTypes.string,
}

export default PreFooter
