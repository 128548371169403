import styled from '@emotion/styled'
import Title from 'components/Utilities/Title'
import Text from 'components/Utilities/Text'

export const ProjectDetailsBannerContainer = styled.div`
position: absolute;
bottom: 0;
left: 20px;
transform: translate(0px,50%);

  @media(max-width: ${props => props.theme.breakpoints.md}) {
    width: calc(100% - 20px);
  }

  @media(min-width: ${props => props.theme.breakpoints.md}) {
    display: flex;
    transform: translate(0, 0);
    left: unset;
    right: 10%;
  }
`

export const DetailsContainer = styled.div`
  padding: 20px;
  border: 1px solid ${props => props.theme.colors.primarygreen};
  min-width: 150px;
  min-height: 110px;
  background-color: ${props => props.theme.colors.white};

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    width: calc(100% - 20px);
  }

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    border-right: ${props =>
      props.lastBox ? `1px solid ${props.theme.colors.primarygreen}` : '0'};
    max-height: 200px;
  }

  @media (min-width: ${props => props.theme.breakpoints.l}) {
    padding-right: 60px;
    min-width: 250px;
  }
`

export const StyledTitle = styled(Title)`
  font-weight: bold;
  margin-bottom: 0;
  color: ${props => props.theme.colors.primarygreen}!important;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    margin-bottom: 20px;
  }
`
export const StyledBody = styled(Text)`
  p {
    font-weight: 200;
    white-space: pre-line;
    color: ${props => props.theme.colors.primarygreen}!important;
    margin-bottom: 0px;
  }
`
