import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import {
  ProjectDetailsBannerContainer,
  DetailsContainer,
  StyledTitle,
  StyledBody,
} from './styles'

const ProjectDetailsBanner = ({ query, locale }) => {
  const data = useStaticQuery(graphql`
    query ProjectDetailsQuery {
      generic: allPrismicProjectsGeneral {
        nodes {
          lang
          data {
            cta {
              page_type
              cta_styling
              cta_link {
                uid
              }
              cta_label {
                text
              }
            }
            tab_1_title {
              text
            }
            tab_2_title {
              text
            }
            tab_3_title {
              text
            }
            tab_4_title {
              text
            }
          }
        }
      }
    }
  `)

  const projectDetailsData = data.generic.nodes
    .filter(node => node.lang === locale)
    .map(r => r.data)

  return (
    <ProjectDetailsBannerContainer>
      <DetailsContainer>
        {projectDetailsData?.[0]?.tab_1_title?.text ? (
          <StyledTitle type="h1-secondary" as="h2">
            {projectDetailsData?.[0]?.tab_1_title?.text}
          </StyledTitle>
        ) : (
          <></>
        )}
        {query?.project_name.html ? (
          <StyledBody
            as="span"
            type="secondary-small"
            dangerouslySetInnerHTML={{ __html: query?.project_name.html }}
          ></StyledBody>
        ) : (
          <></>
        )}
      </DetailsContainer>
      <DetailsContainer>
        {projectDetailsData?.[0]?.tab_2_title?.text ? (
          <StyledTitle type="h1-secondary" as="h2">
            {projectDetailsData?.[0]?.tab_2_title?.text}
          </StyledTitle>
        ) : (
          <></>
        )}
        {query?.client.html ? (
          <StyledBody
            type="secondary-small"
            as="span"
            dangerouslySetInnerHTML={{ __html: query?.client.html }}
          ></StyledBody>
        ) : (
          <></>
        )}
      </DetailsContainer>
      <DetailsContainer>
        {projectDetailsData?.[0]?.tab_3_title?.text ? (
          <StyledTitle type="h1-secondary" as="h2">
            {projectDetailsData?.[0]?.tab_3_title?.text}
          </StyledTitle>
        ) : (
          <></>
        )}
        {query?.conception.html ? (
          <StyledBody
            type="secondary-small"
            as="span"
            dangerouslySetInnerHTML={{ __html: query?.conception.html }}
          ></StyledBody>
        ) : (
          <></>
        )}
      </DetailsContainer>
      <DetailsContainer lastBox>
        {projectDetailsData?.[0]?.tab_4_title?.text ? (
          <StyledTitle type="h1-secondary" as="h2">
            {projectDetailsData?.[0]?.tab_4_title?.text}
          </StyledTitle>
        ) : (
          <></>
        )}
        {query?.location.html ? (
          <StyledBody
            as="span"
            type="secondary-small"
            dangerouslySetInnerHTML={{ __html: query?.location.html }}
          ></StyledBody>
        ) : (
          <></>
        )}
      </DetailsContainer>
    </ProjectDetailsBannerContainer>
  )
}

ProjectDetailsBanner.propTypes = {
  query: PropTypes.object.isRequired,
  locale: PropTypes.string.isRequired,
}

export default ProjectDetailsBanner
