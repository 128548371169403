import styled from '@emotion/styled'
import Title from 'components/Utilities/Title'
import BackgroundImage from 'gatsby-background-image'

export const PreFooterContainer = styled.div`
  background-color: ${props => props.theme.colors.lightblue};
  padding-top: 80px;
  padding-bottom: 150px;
  display: flex;
  flex-direction: column;
  text-align: center;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    width: 100%;
    min-height: 790px;
    padding-bottom: 150px;
    margin-top: 150px;
    padding-top: initial;

    .Prefooter__Image {
      margin-right: 20px;
    }

    .Prefooter__oneImageOnly {
      margin-right: 0px;
    }
  }

  .Prefooter__link {
    text-decoration: none;
    width: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .Prefooter__Link-seeAll {
    color: ${props => props.theme.colors.primarygreen};
  }

  .Project__Prefooter-widthLimiter {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: center;

    @media (min-width: ${props => props.theme.breakpoints.md}) {
      padding: 75px 50px;
      flex-direction: row;
    }

    @media (min-width: ${props => props.theme.breakpoints.l}) {
      padding: 130px 100px;
    }
  }
`

export const PrefooterCard = styled(BackgroundImage)`
  width: 250px;
  height: 170px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100% - 80px);
  margin: 0 auto;
  margin-bottom: 30px;
  transition: background-color 0.7s;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    max-width: 640px;
    min-height: 320px;
    margin: initial;
  }

  @media (min-width: ${props => props.theme.breakpoints.l}) {
    min-height: 420px;
  }

  &:hover {
    .Prefooter__Title {
      background-color: ${props => props.theme.colors.terracota};
    }
  }
`
export const StyledTitle = styled(Title)`
  font-weight: 400;
  width: auto;
  padding: 12px 24px;
  transition: background-color 0.7s;
`
