import React from 'react'
import PropTypes from 'prop-types'
import { WidthLimiterContainer } from './styles'

const WidthLimiter = ({ children, className }) => {
  return (
    <WidthLimiterContainer className={className ? className : ''}>
      {children}
    </WidthLimiterContainer>
  )
}

WidthLimiter.propTypes = {
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
}

export default WidthLimiter
