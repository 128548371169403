export const getHeaderContent = query => {
  const data =
    query?.length > 0 &&
    query?.map((el, index) => {
      return {
        primaryTitle:
          el?.generic_component?.document?.data?.title_primary?.text,
        secondaryTitle:
          el?.generic_component?.document?.data?.title_secondary?.text,
        index: index,
        body: el?.generic_component?.document?.data?.body?.text,
        line_decoration: el?.generic_component?.document?.data?.line_decoration,
        component_position:
          el?.generic_component?.document?.data?.component_position,
        font_weight_title_primary:
          el?.generic_component?.document?.data?.font_weight_title_primary,
        font_weight_title_secondary:
          el?.generic_component?.document?.data?.font_weight_title_secondary,
        heading_is_scrollable:
          el?.generic_component?.document?.data?.heading_is_scrollable,
      }
    })
  return data
}
